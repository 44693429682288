/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { NavContext } from 'react-sidenav';
import styles from '../SideNavbar.module.scss';
import * as SubheaderActions from '../../../state/actions/common-actions';
import { excludeUser } from '../../../constants/exclude-modules-user';
import ModalAbandoneProject from '../../BitaModal/ModalabandoneProject';
import { hasValidMorganStanlyOnly } from '../../../utils/utils';
import { useCurrentModule } from '../../../bitaHooks';
import * as sidebarReducer from '../../../state/actions/sidebar-actions';

const NavTitle = ({ title, to, children, appendSubheader, resetSubheader, collapsed, status }) => {
  const [showChildren, setShowChildren] = React.useState(false);
  const context = React.useContext(NavContext);
  const selected = context.selected && showChildren ? styles.selected : '';
  // automatically hidde submenu subsections from side bar
  // const showSubmenu = state.selectedSection.includes(title);
  const [visibleConfirmation, setVisibleConfirmation] = React.useState(null);
  const summaryProyect = Object.keys(useSelector(state => state.summary));
  const apiData = useSelector(state => state.apiData.apiData);
  const userdata = useSelector(state => state.auth.userdata);
  const typeMethodology = useSelector(state => state.common.subheader);
  const sidebarSelectedModule = useSelector(state => state.sidebar.selectedModule);
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentModule] = useCurrentModule();

  if (
    hasValidMorganStanlyOnly(userdata.id_company) &&
    (title === 'STRUCTURED INDEX' || title === 'OPTIMIZED INDEX')
  ) {
    status = 'INACTIVE';
  }

  const currentModuleUrl = location.pathname.split('/')[1];
  const isHidden =
    status === 'HIDDEN' ||
    (title === 'OPTIMIZED INDEX' &&
      userdata.id_company === process.env.REACT_APP_DEMO_COMPANY_ID) ||
    (excludeUser[userdata?.company?.name] &&
      excludeUser[userdata?.company?.name]?.[title?.toLowerCase()]);

  const pathMap = {
    'portfolio-basket-calculation': 'PORTFOLIO / BASKET BACKTESTING',
    '?menu=live': 'BASKET MANAGEMENT MODULES',
    'live-calculation': 'BASKET MANAGEMENT MODULES',
    'structures-product': 'STRUCTURED INDEX',
    'rebalancing-reconstitution': 'REBALANCING & RECONSTITUTION',
    'index-analyzer': 'ANALYZER',
    'factsheet-builder': 'FACTSHEET BUILDER',
    'universe-builder': 'UNIVERSE BUILDER',
    'index-builders': 'INDEX BUILDER',
    'optimized-index': 'OPTIMIZED INDEX',
  };
  useEffect(() => {
    const selectedModule = Object.keys(pathMap).find(key => location.pathname.includes(key));
    if (selectedModule) {
      dispatch(sidebarReducer.setSelectedModule(pathMap[selectedModule]));
    } else {
      dispatch(sidebarReducer.setSelectedModule('HOME'));
    }
  }, [location]);

  const shouldShowConfirmationModal =
    summaryProyect.length > 0 ||
    (apiData?.['Optimized Index'] &&
      Object.keys(apiData?.['Optimized Index'])?.length > 0 &&
      to.split('/')[1] !== currentModuleUrl);

  const isBasketManagementModuleSelected = sidebarSelectedModule === 'BASKET MANAGEMENT MODULES';
  const isAnalizerSelected = sidebarSelectedModule === 'ANALYZER';
  const isMainAnalizerPageSelected = window.location.pathname === '/index-analyzer/analyzer';

  return (
    <>
      {shouldShowConfirmationModal ||
      isBasketManagementModuleSelected ||
      isMainAnalizerPageSelected ? (
        <div
          className={title === sidebarSelectedModule && styles.disabled}
          onClick={() => {
            setVisibleConfirmation(true);
          }}
        >
          <div
            className={classNames(
              styles.sideNavbarTitle,
              title === sidebarSelectedModule && styles.selected,
            )}
            style={{
              opacity: status === 'ACTIVE' ? '1' : '0.5',
              display: isHidden ? 'none' : 'block',
            }}
          >
            {title}
          </div>
        </div>
      ) : (
        <Link
          // eslint-disable-next-line no-nested-ternary
          className={title === sidebarSelectedModule && !isAnalizerSelected && styles.disabled}
          to={status === 'ACTIVE' ? to : '#'}
          onClick={() => {
            dispatch(sidebarReducer.setSelectedModule(title));
            if (status === 'ACTIVE') {
              setShowChildren(prev => !prev);
              resetSubheader();
              appendSubheader(
                typeMethodology !== 'INDEX BUILDER' && title === 'INDEX BUILDER'
                  ? 'CREATE NEW METHODOLOGY · WHITE CANVAS'
                  : title,
              );
            }
          }}
        >
          <div
            className={classNames(
              styles.sideNavbarTitle,
              title === sidebarSelectedModule && styles.selected,
            )}
            style={{
              opacity: status === 'ACTIVE' ? '1' : '0.5',
              display: isHidden ? 'none' : 'block',
              cursor: status === 'ACTIVE' ? 'pointer' : 'default',
            }}
          >
            {title}
          </div>
        </Link>
      )}

      {!collapsed && children}
      <ModalAbandoneProject
        visibility={visibleConfirmation}
        to={to}
        close={() => setVisibleConfirmation(false)}
      />
    </>
  );
};

NavTitle.defaultProps = {
  to: '/',
};

const mapStateToProps = state => ({
  selectedSection: state.common.subheader,
});

const mapDispatchToProps = dispatch => {
  return {
    resetSubheader: () => dispatch(SubheaderActions.resetSubheader()),
    appendSubheader: string => dispatch(SubheaderActions.appendSubheader(string)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavTitle);
