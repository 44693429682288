/* eslint-disable func-names */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS } from 'immutable';
import {
  roundUpPercentageString,
  versionIndexName,
  hasValidBitaDomain,
} from '../../../utils/utils';
import BitaCharts from '../../../bitaComponents/BitaCharts/BitaCharts';
import { historicalRatingsTheme } from '../../../bitaComponents/BitaCharts/Themes';
import styles from './PerformanceAndRisk.module.scss';
import '../AnalyzerComponents.scss';
import { excludeUser } from '../../../constants/exclude-modules-user';
import ModalBasketCalculation from '../ModalBasketCalculation/ModalBasketCalculation';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaSpinner from '../../BitaSpinner/BitaSpinner';
import BitaSelect from '../../BitaSelect/BitaSelect';
import BitaSearchBar from '../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import ConfigYAML from '../../../config/ConfigYAML';
import { selectUniverse } from '../../../state/actions/universe-actions';
import { updateUniverse, updateMethodology } from '../../../state/actions/module-selection-actions';
import { getIndexBenchmark } from '../../../state/actions/benchmark-actions';
import * as Action from '../../../state/actions/api-data-actions';
import RunBacktestModal from '../../../pages/runBacktestModal';
import RunRebalancingReconstitution from '../../../pages/runRebalancingReconstitution';

import { DownloadCenterModal } from '../../BitaModal/DownloadCenterModal';
import { IconsModules } from '../../../constants/icons-module';
import IndexDescription from '../IndexDescription';
import DownloadCenter from '../DownloadCenter';
import TableData from './lib/TableData';
import FeaturesTable from './lib/FeaturesTable';
import Table from './lib/Table';
import RadioButtons from './lib/RadiotButtons';
import { esgScores, gcScores, sustainalyticsESG, features } from './lib/Data';

const PerformanceAndRisk = ({ benchmarkList }) => {
  const [indexStats, setIndexStats] = useState(fromJS({}));
  const [benchmarkStats, setBenchmarkStats] = useState(fromJS({}));
  const [pseudoTimeout, setPseudoTimeout] = useState(false);
  const [counter, setCounter] = useState(0);
  const [indexValues, setIndexValues] = useState([]);
  const [benchmarkValues, setBenchmarkValues] = useState([]);
  const optionsBaseDefault = benchmarkList || [];
  const [riskModel, setRiskModel] = useState(11);
  const [datesArray, setDateArray] = useState([]);
  const [optionsBase, setOptionsBase] = React.useState(optionsBaseDefault[0]);
  const apiData = useSelector(state => state.apiData);
  const company = useSelector(state => state.auth.company);
  const backtestData = useSelector(state => state.backtest);
  const [indexBenchmark, setIndexBenchmark] = useState();
  const [template, setTemplate] = useState({ id: 'ESG Score', value: 'ESG Score' });
  const [providers, setProviders] = useState({ id: 'Arabesque', value: 'Arabesque' });
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('5y');

  const [featuresdate, setFeaturesDate] = useState();
  const [datesDistribution, setDatesDistribution] = useState();
  const [esgScoredate, setEsgScoreDate] = useState();
  const [esgData, setESGData] = useState();
  const [gcScoresSelected, setGCScoresSelected] = useState(gcScores[0]);
  const [dataFeatures, setDateFeatures] = useState();
  const [dataGCScores, setDatagcScores] = useState({ index: [] });
  const [dataGCScoresTable, setDataGCScoresTable] = useState();
  const [esgScoresSelected, setESGScoresSelected] = useState(esgScores[0]);
  const [dataESGScoresTable, setDataESGScoresTable] = useState();
  const [dataESGScores, setDataESGScores] = useState();
  const [sustainalyticsESGSelected, setSustainalyticsESGSelected] = useState(sustainalyticsESG[0]);
  const [dataSustainalyticsESGTable, setDatasustainalyticsESGTable] = useState();
  const [dataSustainalyticsESG, setDataSustainalyticsESG] = useState();
  const userData = useSelector(state => state.auth.userdata);

  const dispatch = useDispatch();
  const ModalBCRef = useRef();
  const modalRef = useRef();
  const ModalRRRef = useRef();
  const index_list = _.get(apiData, ['apiData', 'Indexes', 'data'], []);
  const currentIndexSelected = useSelector(
    state => state.apiData.apiData['Current Index Selected'],
  );
  const token = useSelector(state => state.auth.token);

  const { indexId } = useParams();

  const isShowActivateCalculation =
    hasValidBitaDomain(userData?.email) || userData?.company?.name === 'demo_company';

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Index Stats']) {
      setIndexStats(fromJS(apiData.apiData['Index Stats']));
    }
  }, [apiData.apiData['Index Stats']]);

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Benchmark Card Stats']) {
      setBenchmarkStats(fromJS(apiData.apiData['Benchmark Card Stats']));
    }
  }, [apiData.apiData['Benchmark Card Stats']]);

  let ImageI;
  if (currentIndexSelected?.data?.index_type === 'regular') {
    ImageI = IconsModules.IndexModule;
  } else if (currentIndexSelected?.data?.index_type === 'optimized_index') {
    ImageI = IconsModules.OptimizedModule;
  } else if (currentIndexSelected?.data?.index_type === 'basket_index') {
    ImageI = IconsModules.PortfolioBasketModule;
  } else {
    ImageI = IconsModules.StructureIModule;
  }

  const datesConfig = {
    lastDateOfYear: '12-31',
    lastDateOfYearAlternative: '12-30',
    fallbackDate: '09-30',
  };

  function filterDataByDates(data) {
    return data.filter(item => {
      const currentYear = new Date(item.timestamp).getFullYear().toString();
      const lastQuarterYearExist = data.some(
        option =>
          option.timestamp.includes(`${currentYear}-${datesConfig.lastDateOfYear}`) ||
          option.timestamp.includes(`${currentYear}-${datesConfig.lastDateOfYearAlternative}`),
      );

      if (lastQuarterYearExist) {
        return (
          item.timestamp.includes(datesConfig.lastDateOfYear) ||
          item.timestamp.includes(datesConfig.lastDateOfYearAlternative)
        );
      }
      return item.timestamp.includes(datesConfig.fallbackDate);
    });
  }

  const getIndexData = (data, timeframe, startDate) => {
    return data.filter(item => {
      const timestamp = moment(item.timestamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
      return timeframe === 'max' || timestamp.isSameOrAfter(startDate);
    });
  };

  const getQuarterlyTimeframes = (data, timeframe) => {
    return data.map(item => {
      return timeframe !== 'max'
        ? `Q${moment(item.timestamp)
            .utc()
            .quarter()}-${moment(item.timestamp).year()}`
        : moment(item.timestamp).year();
    });
  };

  const getDateFromTimestamp = timestamp => {
    return moment(timestamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
  };

  const processData = (indexSt, benchmarkSt, selectedTFrame, selectItem) => {
    const benchmarkData = benchmarkSt.get('data')?.toJS()?.[selectItem.id]?.value || [];
    const indexData = indexSt.get('data')?.toJS()?.[selectItem.id]?.value || [];

    // Sort data
    benchmarkData.sort(
      (a, b) => getDateFromTimestamp(a.timestamp) - getDateFromTimestamp(b.timestamp),
    );
    indexData.sort((a, b) => getDateFromTimestamp(a.timestamp) - getDateFromTimestamp(b.timestamp));

    const dataTimeframe = selectedTFrame === '1y' ? 1 : selectedTFrame === '3y' ? 3 : 5;
    const startDate = getDateFromTimestamp(indexData[indexData.length - 1].timestamp).subtract(
      dataTimeframe,
      'years',
    );

    const filteredIndexData = getIndexData(indexData, selectedTFrame, startDate);
    const filteredBenchmarkData = getIndexData(benchmarkData, selectedTFrame, startDate);

    const quaterlyTimeframes = getQuarterlyTimeframes(filteredIndexData, selectedTFrame);
    const benchmarkValuesDate = filteredBenchmarkData.map(item => Number(item.value).toFixed(2));
    const indexValuesDate = filteredIndexData.map(item => Number(item.value).toFixed(2));

    return {
      timeframeCharp:
        selectedTFrame === 'max'
          ? quaterlyTimeframes
          : quaterlyTimeframes.splice(1, dataTimeframe * 4),
      index:
        selectedTFrame === 'max' ? indexValuesDate : indexValuesDate.splice(1, dataTimeframe * 4),
      benchmark:
        selectedTFrame === 'max'
          ? benchmarkValuesDate
          : benchmarkValuesDate.splice(1, dataTimeframe * 4),
    };
  };

  const generateYears = value => {
    return value?.[0]?.data?.map(object => new Date(object.timestamp).getFullYear().toString());
  };

  useEffect(() => {
    if (currentIndexSelected.data && currentIndexSelected.data.benchmark_id) {
      setIndexBenchmark(currentIndexSelected.data.benchmark_id);

      const usedBenchmark = optionsBaseDefault.filter(
        bench => bench.id === currentIndexSelected.data.benchmark_id,
      );
      if (usedBenchmark.length > 0) setOptionsBase(usedBenchmark[0]);
      const riskModelList = _.get(
        apiData,
        ['apiData', 'risk_models', 'data', 'Risk Model Bita'],
        [],
      );
      const risk = riskModelList.filter(r => r.name === currentIndexSelected.data.risk_name);
      if (risk.length > 0) {
        setRiskModel(risk[0]);
      }
    }
  }, [currentIndexSelected, benchmarkList]);

  useEffect(() => {
    if (indexId && indexBenchmark) {
      getIndexBenchmark(indexId, indexBenchmark, token).then(resp => {
        dispatch(
          Action.setApiData({
            title: 'Benchmark Card Stats',
            data: resp.data,
          }),
        );
      });
    }
  }, [indexBenchmark]);

  useEffect(() => {
    if (indexId) {
      dispatch(Action.setCurrentIndex(token, indexId));
      dispatch(Action.setIndexStats(token, indexId));
    }
  }, [indexId]);

  useEffect(() => {
    const dateList = _.get(currentIndexSelected, 'data.dates_constitutions_real', {});
    if (dateList) {
      setDateArray(
        Object.entries(dateList)
          .slice()
          .reverse()
          .map(([key, date], i) => {
            return { id: i, value: key, date };
          }),
      );
    }
  }, [currentIndexSelected]);

  useEffect(() => {
    setFeaturesDate(datesArray[0] || '');
  }, [datesArray]);

  useLayoutEffect(() => {
    const arr = indexStats.getIn(['data', '45', 'value']) || fromJS([]);
    const formattedValues = arr
      .sort((a, b) => {
        const currentDate = new Date(a.get('timestamp'));
        const nextDate = new Date(b.get('timestamp'));
        if (currentDate < nextDate) {
          return 1;
        }
        if (currentDate > nextDate) {
          return -1;
        }
        return 0;
      })
      .map(stat =>
        fromJS({
          date: stat.get('timestamp'),
          x: new Date(stat.get('timestamp')),
          y: roundUpPercentageString(stat.get('value')),
          name: stat.get('timestamp'),
        }),
      );

    setIndexValues(formattedValues.slice().reverse());
  }, [indexStats]);

  useLayoutEffect(() => {
    const arr = benchmarkStats.getIn(['data', '45', 'value']) || fromJS([]);

    const formattedValues = arr
      .sort((a, b) => {
        const currentDate = new Date(a.get('timestamp'));
        const nextDate = new Date(b.get('timestamp'));
        if (currentDate < nextDate) {
          return 1;
        }
        if (currentDate > nextDate) {
          return -1;
        }
        return 0;
      })
      .map(stat =>
        fromJS({
          date: stat.get('timestamp'),
          y: roundUpPercentageString(stat.get('value')),
          x: new Date(stat.get('timestamp')),
          name: new Date(stat.get('timestamp')),
        }),
      );

    setBenchmarkValues(formattedValues.slice().reverse());
  }, [benchmarkStats]);

  useEffect(() => {
    const _dataESGScoresTable = [];
    esgScores.forEach(item => {
      const _index = indexStats.get('data')?.toJS()?.[item.id]?.value || [];
      _index.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      const dataStats = filterDataByDates(_index);
      _dataESGScoresTable.push({ name: item.name, data: dataStats });
    });
    setDataESGScoresTable(_dataESGScoresTable);
  }, [indexStats]);

  useEffect(() => {
    setDataESGScores({});
    const _index = indexStats.get('data')?.toJS()?.[esgScoresSelected.id]?.value || [];
    if (_index.length > 0) {
      setDataESGScores(
        processData(indexStats, benchmarkStats, selectedTimeFrame, esgScoresSelected),
      );
    }
  }, [indexStats, esgScoresSelected, selectedTimeFrame]);

  useEffect(() => {
    const _datafeatures = [];
    features.forEach(feature => {
      const _benchmark = benchmarkStats.get('data')?.toJS()?.[feature.id]?.value;
      const _index = indexStats.get('data')?.toJS()?.[feature.id]?.value;
      if (
        (_benchmark || []).filter(_feature => _feature.timestamp.includes(featuresdate.date))
          .length > 0
      )
        _datafeatures.push({
          id: feature.id,
          name: feature.feature_name,
          index: (_index || []).filter(_feature =>
            _feature.timestamp.includes(featuresdate.date),
          )?.[0]?.value,
          benchmark: (_benchmark || []).filter(_feature =>
            _feature.timestamp.includes(featuresdate.date),
          )?.[0]?.value,
        });
    });
    setDateFeatures(_datafeatures);
  }, [featuresdate]);

  useEffect(() => {
    const idData = template?.id === 'ESG Score' ? 255 : 256;
    const _benchmark = benchmarkStats
      .get('data')
      ?.toJS()
      ?.[idData]?.value.map((distribution, i) => {
        return {
          id: i,
          value: `Q${moment(distribution.timestamp)
            .utc()
            .quarter()}-${moment(distribution.timestamp).year()}`,
          date: moment(distribution.timestamp).format('YYYY-MM'),
        };
      });
    setDatesDistribution(_benchmark);
    setEsgScoreDate(_benchmark?.[0] || '');
  }, [benchmarkStats, template]);

  useEffect(() => {
    const idData = template?.id === 'ESG Score' ? 255 : 256;
    const _benchmark = benchmarkStats
      .get('data')
      ?.toJS()
      ?.[idData]?.value?.find(distribution => distribution.timestamp.includes(esgScoredate.date));
    const _index = indexStats
      .get('data')
      ?.toJS()
      ?.[idData]?.value?.find(distribution => distribution.timestamp.includes(esgScoredate.date));
    setESGData({
      header: Object.keys(_benchmark?.value || []),
      data: [
        {
          name:
            currentIndexSelected?.data?.index_type === 'rebalancing_module' ? 'Portfolio' : 'Index',
          data:
            Object.entries(_index?.value || {}).map(item => {
              return { timestamp: item[0], value: (item[1] * 100).toFixed(3) };
            }) || [],
        },
        {
          name: 'Benchmark',
          data:
            Object.entries(_benchmark?.value || {}).map(item => {
              return { timestamp: item[0], value: (item[1] * 100).toFixed(3) };
            }) || [],
        },
      ],
    });
  }, [esgScoredate, benchmarkStats, indexStats, template]);

  useEffect(() => {
    const _dataGCScoresTable = [];
    gcScores.forEach(item => {
      const _index = indexStats.get('data')?.toJS()?.[item.id]?.value || [];
      _index.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      const dataStats = filterDataByDates(_index);
      _dataGCScoresTable.push({ name: item.name, data: dataStats });
    });
    setDataGCScoresTable(_dataGCScoresTable);
  }, [indexStats]);

  useEffect(() => {
    setDatagcScores({ index: [] });
    const _index = indexStats.get('data')?.toJS()?.[gcScoresSelected.id]?.value || [];
    if (_index.length > 0) {
      setDatagcScores(processData(indexStats, benchmarkStats, selectedTimeFrame, gcScoresSelected));
    }
  }, [indexStats, gcScoresSelected, selectedTimeFrame]);

  useEffect(() => {
    const _dataSustainalyticsESGTable = [];
    sustainalyticsESG.forEach(item => {
      const _index = indexStats.get('data')?.toJS()?.[item.id]?.value || [];
      _index.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      const dataStats = filterDataByDates(_index);
      _dataSustainalyticsESGTable.push({ name: item.name, data: dataStats });
    });
    setDatasustainalyticsESGTable(_dataSustainalyticsESGTable);
  }, [indexStats]);

  useEffect(() => {
    setDataSustainalyticsESG({});
    const _index = indexStats.get('data')?.toJS()?.[sustainalyticsESGSelected.id]?.value || [];
    if (_index.length > 0) {
      setDataSustainalyticsESG(
        processData(indexStats, benchmarkStats, selectedTimeFrame, sustainalyticsESGSelected),
      );
    }
  }, [indexStats, sustainalyticsESGSelected, selectedTimeFrame]);

  const isSameAsBacktest = () => {
    if (
      backtestData &&
      currentIndexSelected &&
      currentIndexSelected.data &&
      backtestData.id === currentIndexSelected.data.id
    ) {
      return true;
    }
    return false;
  };

  const filesDownloadCenter = isSameAsBacktest()
    ? _.get(backtestData, 'pdfs', {
        status: 'loading',
      })
    : _.get(apiData, 'apiData.[Download Center]', {
        status: 'loading',
      });
  const timer = ConfigYAML.jsonPath(['analyzer', 'timeout_files']);
  const filesSpinnerMessage = ConfigYAML.jsonPath(['analyzer', 'timeout_text']);
  useEffect(() => {
    if (indexStats.size > 0) {
      setTimeout(() => {
        setPseudoTimeout(true);
      }, timer);
    }
  }, [indexStats]);

  const getMethodology = idMeth => {
    const allMethodologies = _.get(apiData, 'apiData.[Methodologies]', {});
    const selectedMeth = allMethodologies.data.filter(m => m.id === idMeth)[0];
    if (selectedMeth) {
      dispatch(updateMethodology(selectedMeth));
      dispatch(
        Action.setApiData({
          title: 'methodologySelected',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'methodologySelectedCard',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
    }
  };

  const getUniverse = idUniverse => {
    const allUniverses = _.get(apiData, 'apiData.[AllUniverses]', {});
    const selectedUni = allUniverses.data.filter(u => u.id === idUniverse)[0];
    if (selectedUni) {
      dispatch(selectUniverse(selectedUni));
      dispatch(updateUniverse(selectedUni));
      dispatch(
        Action.setApiData({
          title: 'universeSelected',
          data: selectedUni,
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'universeSelectedCard',
          data: selectedUni,
        }),
      );
    }
  };

  const activeRunBackTest = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      const idUniverse = currentIndexSelected.data.universe_id;
      const idMethodology = currentIndexSelected.data.methodology_id;
      getUniverse(idUniverse);
      getMethodology(idMethodology);
      dispatch(
        Action.setApiData({
          title: 'indexBacktest',
          data: {
            'Index Backtest': {
              'INDEX NAME': versionIndexName(currentIndexSelected.data.name, index_list),
              'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
              'Start Date': currentIndexSelected.data.start_date.split('T')[0],
              'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
              Benchmark: optionsBase,
              risk_model_id: riskModel,
              RiskOption: riskModel,
            },
          },
        }),
      );
      modalRef.current.runModal();
    }
    return {};
  };

  const activeRebalanceReconstitution = () => {
    dispatch(
      Action.setApiData({
        title: 'indexBacktest',
        data: {
          'Index Backtest': {
            'PORTFOLIO NAME': versionIndexName(currentIndexSelected.data.name, index_list),
            'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
            'Start Date': currentIndexSelected.data.start_date.split('T')[0],
            'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
            Benchmark: optionsBase,
            risk_model_id: riskModel,
            RiskOption: riskModel,
          },
        },
      }),
    );
    const idUniverse = currentIndexSelected.data.universe_id;
    const idMethodology = currentIndexSelected.data.methodology_id;
    getUniverse(idUniverse);
    getMethodology(idMethodology);
    ModalRRRef.current.runModal();
  };

  const getIndexName = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      return currentIndexSelected.data.name;
    }
    return '';
  };

  const dataDropdowns = {
    Arabesque: [
      { id: 'ESG Score', value: 'ESG Score' },
      { id: 'GC Score', value: 'GC Score' },
      { id: 'Feature Score', value: 'Category' },
      { id: 'Feature Score', value: 'Dimension' },
    ],
    Sustainalytics: [{ id: 'ESG Risk Score', value: 'ESG Risk Score' }],
  };

  const viewTemplate = select => {
    switch (select) {
      case 'Feature Score':
        return (
          <div className={styles.tableContainer}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span style={{ fontWeight: 'bold' }} />
              <BitaSelect
                data={datesArray}
                value={featuresdate}
                clearable={false}
                handleSelection={selected => {
                  setFeaturesDate(selected);
                }}
              />
            </div>
            <FeaturesTable data={dataFeatures} />
          </div>
        );
      case 'GC Score':
        return (
          <div>
            <RadioButtons
              selected={selectedTimeFrame}
              setSelected={value => setSelectedTimeFrame(value)}
            />
            <div className={styles.tableContainer}>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>Historical Ratings</span>
                <BitaSelect
                  data={gcScores}
                  value={gcScoresSelected}
                  clearable={false}
                  handleSelection={selected => {
                    setGCScoresSelected(selected);
                  }}
                />
              </div>
              <BitaCharts
                theme={historicalRatingsTheme}
                options={{
                  xAxis: {
                    categories: dataGCScores?.timeframeCharp,
                    crosshair: true,
                  },
                  series: [
                    {
                      name:
                        currentIndexSelected?.data?.index_type === 'rebalancing_module'
                          ? 'PORTFOLIO'
                          : 'INDEX',
                      data:
                        dataGCScores?.index?.map(item => {
                          return Number(item);
                        }) || [],
                    },
                    {
                      name: 'BENCHMARK',
                      data:
                        dataGCScores?.benchmark?.map(item => {
                          return Number(item);
                        }) || [],
                    },
                  ],
                  lang: {
                    noData: "There's no data available for the time frame you've selected",
                  },
                  tooltip: {
                    crosshairs: true,
                    shared: true,
                  },
                }}
                containerProps={{ style: { height: '310px', width: '100%' } }}
              />
            </div>
            <div className={styles.tableContainer}>
              <div style={{ fontSize: '10px', width: '100%' }}>
                <div>
                  <Table
                    data={dataGCScoresTable}
                    years={generateYears(dataGCScoresTable)}
                    text={
                      currentIndexSelected?.data?.index_type === 'rebalancing_module'
                        ? 'Portfolio Ratings'
                        : 'Index Ratings'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 'ESG Score':
        return (
          <div>
            <RadioButtons
              selected={selectedTimeFrame}
              setSelected={value => setSelectedTimeFrame(value)}
            />
            <div className={styles.tableContainer}>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>Historical Ratings</span>
                <BitaSelect
                  data={esgScores}
                  value={esgScoresSelected}
                  clearable={false}
                  handleSelection={selected => {
                    setESGScoresSelected(selected);
                  }}
                />
              </div>
              <BitaCharts
                theme={historicalRatingsTheme}
                options={{
                  xAxis: {
                    categories: dataESGScores?.timeframeCharp,
                    crosshair: true,
                  },
                  series: [
                    {
                      name:
                        currentIndexSelected?.data?.index_type === 'rebalancing_module'
                          ? 'PORTFOLIO'
                          : 'INDEX',
                      data:
                        dataESGScores?.index?.map(item => {
                          return Number(item);
                        }) || [],
                    },
                    {
                      name: 'BENCHMARK',
                      data:
                        dataESGScores?.benchmark?.map(item => {
                          return Number(item);
                        }) || [],
                    },
                  ],
                  lang: {
                    noData: "There's no data available for the time frame you've selected",
                  },
                }}
                containerProps={{ style: { height: '310px', width: '100%' } }}
              />
            </div>
            <div className={styles.tableContainer}>
              <div>
                <Table
                  data={dataESGScoresTable}
                  years={generateYears(dataESGScoresTable)}
                  text={
                    currentIndexSelected?.data?.index_type === 'rebalancing_module'
                      ? 'Portfolio Ratings'
                      : 'Index Ratings'
                  }
                />
              </div>
            </div>
          </div>
        );
      case 'ESG Risk Score':
        return (
          <div>
            <RadioButtons
              selected={selectedTimeFrame}
              setSelected={value => setSelectedTimeFrame(value)}
            />
            <div className={styles.tableContainer}>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>Historical Ratings</span>
                <BitaSelect
                  data={sustainalyticsESG}
                  value={sustainalyticsESGSelected}
                  clearable={false}
                  handleSelection={selected => {
                    setSustainalyticsESGSelected(selected);
                  }}
                />
              </div>
              <BitaCharts
                theme={historicalRatingsTheme}
                options={{
                  xAxis: {
                    categories: dataSustainalyticsESG?.timeframeCharp,
                    crosshair: true,
                  },
                  series: [
                    {
                      name:
                        currentIndexSelected?.data?.index_type === 'rebalancing_module'
                          ? 'PORTFOLIO'
                          : 'INDEX',
                      data:
                        dataSustainalyticsESG?.index?.map(item => {
                          return Number(item);
                        }) || [],
                    },
                    {
                      name: 'BENCHMARK',
                      data:
                        dataSustainalyticsESG?.benchmark?.map(item => {
                          return Number(item);
                        }) || [],
                    },
                  ],
                  lang: {
                    noData: "There's no data available for the time frame you've selected",
                  },
                }}
                containerProps={{ style: { height: '310px', width: '100%' } }}
              />
            </div>
            <div className={styles.tableContainer}>
              <div>
                <Table
                  data={dataSustainalyticsESGTable}
                  years={generateYears(dataSustainalyticsESGTable)}
                  text={
                    currentIndexSelected?.data?.index_type === 'rebalancing_module'
                      ? 'Portfolio Ratings'
                      : 'Index Ratings'
                  }
                />
              </div>
            </div>
          </div>
        );
      case 'Sustainalytics ESG Risk Issues':
        return <TableData data="Sustainalytics ESG Risk Issues" />;
      default:
        return <TableData data="Features" />;
    }
  };

  return (
    <div className={styles.base}>
      <RunBacktestModal ubication="analyzer" showData ref={modalRef} disable_title />
      <RunRebalancingReconstitution ubication="analyzer" showData ref={ModalRRRef} disable_title />
      <ModalBasketCalculation ubication="analyzer" showData ref={ModalBCRef} disable_title />
      <DownloadCenterModal />
      <div className={styles.contentContainer}>
        <div className={styles.firstHalfContainer}>
          <div className={styles.halfWidthContainer} style={{ width: 'calc(45% - 20px)' }}>
            <div className={styles.baseInfoContainer}>
              <div className={styles.baseInfoTitleContainer}>
                <span className={styles.baseInfoTitle}>
                  {' '}
                  <ImageI /> {getIndexName()}
                </span>
              </div>
              <IndexDescription
                indexType={currentIndexSelected?.data?.index_type}
                universe={currentIndexSelected?.data?.universe || ''}
                methodology={currentIndexSelected?.data?.methodology || ''}
                start={currentIndexSelected?.data?.start_date.split('T')[0] || ''}
                end={currentIndexSelected?.data?.end_date?.split('T')[0] || ''}
                author={currentIndexSelected?.data?.author || ''}
                benchmark={currentIndexSelected?.data?.benchmark || ''}
                risk={currentIndexSelected?.data?.risk_name || ''}
                parentIndex={currentIndexSelected?.data?.parent_index}
                frontParameters={currentIndexSelected?.data?.front_parameters || {}}
              />
              {currentIndexSelected?.data?.index_type === 'regular' && (
                <div
                  className={styles.benchmarkContainer}
                  style={{
                    marginTop:
                      currentIndexSelected?.data?.index_type === 'optimized_index'
                        ? '-15px'
                        : '0px',
                  }}
                >
                  <span className={styles.benchmarkTitle}>Select New Benchmark</span>
                  <div className={styles.benchmarkSearch}>
                    <div className={styles.benchmarkSearchDropdown}>
                      <BitaSearchBar
                        searchOptions={optionsBaseDefault}
                        onChange={selected => {
                          setOptionsBase(selected);
                        }}
                        value={optionsBase?.value}
                      />
                    </div>
                    <BitaButton
                      primaryWhite
                      onClick={activeRunBackTest}
                      disabled={currentIndexSelected?.data?.index_type === 'structured_index'}
                    >
                      Recalculate
                    </BitaButton>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.downloadContainer}>
              <span className={styles.downloadTitle}>Download Center</span>
              {(filesDownloadCenter.status === 'done' || filesDownloadCenter?.files) &&
              pseudoTimeout ? (
                <DownloadCenter
                  files={Object.assign(
                    {
                      constituents: currentIndexSelected?.data?.constituents_url,
                      machine_readable_constituents_url:
                        currentIndexSelected?.data?.machine_readable_constituents_url,
                      machine_readable_constituents_summary_url:
                        currentIndexSelected?.data?.machine_readable_constituents_summary_url,
                      machine_readable_basket_management_constituents_url:
                        currentIndexSelected?.data?.machine_readable_basket_management_constituents_url,
                      price: currentIndexSelected?.data?.price_series_url,
                      guidebook: currentIndexSelected?.data?.guidebook_url,
                    },
                    filesDownloadCenter.files,
                  )}
                  company={company}
                  indexType={currentIndexSelected?.data?.index_type}
                  frontParameters={currentIndexSelected?.data?.front_parameters || {}}
                />
              ) : (
                <div>
                  <BitaSpinner />
                  {filesSpinnerMessage}
                </div>
              )}
            </div>
          </div>
          <div
            className={styles.generalStatsContainer}
            style={{ width: 'calc(60% - 20px)', minHeight: '600px' }}
          >
            {isShowActivateCalculation &&
              !(
                currentIndexSelected?.data?.index_type === 'regular' ||
                currentIndexSelected?.data?.index_type === 'rebalancing_module'
              ) && (
                <div
                  style={{
                    position: 'relative',
                    top: '-85px',
                    left: '20px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <BitaButton
                    primaryWhite
                    style={{
                      whiteSpace: 'nowrap',
                      width: '200px',
                      marginRight: '10px',
                      padding: '5px',
                      display: excludeUser[userData?.company?.name] ? 'none' : '',
                    }}
                    onClick={() => {
                      ModalBCRef.current.runModal();
                    }}
                  >
                    Activate in Calculation
                  </BitaButton>
                </div>
              )}
            {(currentIndexSelected?.data?.index_type === 'regular' ||
              currentIndexSelected?.data?.index_type === 'rebalancing_module') && (
              <div
                style={{
                  position: 'relative',
                  top: '-85px',
                  left: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {isShowActivateCalculation && (
                  <BitaButton
                    primaryWhite
                    style={{
                      whiteSpace: 'nowrap',
                      width: '200px',
                      marginRight: '10px',
                      padding: '5px',
                      display: excludeUser[userData?.company?.name] ? 'none' : '',
                    }}
                    onClick={() => {
                      ModalBCRef.current.runModal();
                    }}
                  >
                    Activate in Calculation
                  </BitaButton>
                )}
                <BitaButton
                  primaryWhite
                  style={{ width: '200px', marginRight: '10px' }}
                  onClick={activeRebalanceReconstitution}
                >
                  Rebalance Portfolio/Index
                </BitaButton>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px',
                marginTop: '-27px',
              }}
            >
              <span style={{ fontWeight: 'bold', fontSize: '15px', textTransform: 'uppercase' }}>
                {template.id}
              </span>

              <div
                style={{
                  display: 'flex',
                  zIndex: '999999999999999999',
                }}
              >
                <BitaSelect
                  data={[
                    { id: 'Arabesque', value: 'Arabesque' },
                    { id: 'Sustainalytics', value: 'Sustainalytics' },
                  ]}
                  value={providers}
                  clearable={false}
                  handleSelection={selected => {
                    setProviders(selected || { id: 'Arabesque', value: 'Arabesque' });
                    setTemplate(dataDropdowns[selected?.id ? selected.id : 'Arabesque']?.[0]);
                  }}
                  style={{ marginRight: '10px' }}
                />

                <BitaSelect
                  data={dataDropdowns[providers.id]}
                  value={template}
                  handleSelection={selected => {
                    setTemplate(selected || dataDropdowns[providers.id]?.[0]);
                  }}
                />
              </div>
            </div>
            {viewTemplate(template?.id)}
          </div>
        </div>

        {(template?.id === 'ESG Score' || template?.id === 'ESG Risk Score') && (
          <div className={styles.firstHalfContainer}>
            <div
              className={styles.generalStatsContainer}
              style={{ width: 'calc(42% - 20px)', minHeight: '600px', margin: '3px 10px' }}
            >
              <div>
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      textTransform: 'uppercase',
                      marginBottom: '10px',
                    }}
                  >
                    {template.id} Distribution
                  </span>
                  <BitaSelect
                    data={datesDistribution || []}
                    value={esgScoredate}
                    clearable={false}
                    handleSelection={selected => {
                      setEsgScoreDate(selected);
                    }}
                  />
                </div>
                <div className={styles.tableContainer} style={{ marginTop: '10px' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>Weight</span>
                  </div>
                  <BitaCharts
                    theme={historicalRatingsTheme}
                    options={{
                      xAxis: {
                        categories: esgData?.header.map(item => {
                          return item
                            .replace('(', '')
                            .replace(')', '')
                            .replace(',', '-');
                        }),
                        crosshair: true,
                      },
                      series: [
                        {
                          name:
                            currentIndexSelected?.data?.index_type === 'rebalancing_module'
                              ? 'PORTFOLIO'
                              : 'INDEX',
                          data:
                            esgData?.data?.[0]?.data.map(item => {
                              return Number(item.value);
                            }) || [],
                        },
                        {
                          name: 'BENCHMARK',
                          data:
                            esgData?.data?.[1]?.data.map(item => {
                              return Number(item.value);
                            }) || [],
                        },
                      ],
                      lang: {
                        noData: "There's no data available for the time frame you've selected",
                      },
                      yAxis: {
                        title: {
                          text: '',
                        },
                        max: 100,
                        min: 0,
                        labels: {
                          formatter() {
                            // eslint-disable-next-line react/no-this-in-sfc
                            return `${this.value} %`;
                          },
                          style: {
                            color: '#E0E0E3',
                            fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
                          },
                        },
                      },
                      tooltip: {
                        crosshairs: true,
                        shared: true,
                      },
                    }}
                    containerProps={{ style: { height: '310px', width: '100%' } }}
                  />
                </div>
                <div className={styles.tableContainer}>
                  <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                    {template.id} Distribution
                  </span>

                  <div style={{ fontSize: '10px', width: '100%', marginTop: '10px' }}>
                    <Table
                      data={esgData?.data || []}
                      years={esgData?.header}
                      text="Score Range"
                      type="percentage"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: 'calc(60% - 20px)' }} />
          </div>
        )}
      </div>
    </div>
  );
};
export default PerformanceAndRisk;
