import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingFn,
  SortingState,
} from '@tanstack/react-table';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import { post } from 'axios';
import { BiSolidError, BiX } from 'react-icons/bi';
import { FaCheckSquare, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Icons } from '../../../constants/icons';
import { URL_CONSTANTS } from '../../../api-data-mapping/url-constants';
import {
  CURRENCIES_ARRAY,
  HEADERSIZEINITIAL,
  ORDERED_COLUMNS_WITH_VALID_UBS,
  ORDERED_COLUMNS_WITHOUT_VALID_UBS,
  VISIBILITY_INITIAL,
} from '../utils/constants';
import BitaInput from '../../../components/BitaInput/BitaInput';
import BitaSelect from '../../../components/BitaSelect/BitaSelect';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import { hasValidUBSOnly } from '../../../utils/utils';
import BitaMultiSelect from '../../../components/BitaMultiSelect/BitaMultiSelect';

import BitaCheckbox from '../../../components/BitaCheckbox/BitaCheckbox';
import './EditTable.scss';

const reCalculateCoverage = (tokenUser, data) => {
  return post(URL_CONSTANTS.COVERAGE_REQUEST_UPDATE_URL, data, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const DropdownSelect = props => {
  const { setHeadersContent, headers, defaultHeaders, listNames } = props;
  return (
    <div>
      <BitaMultiSelect
        options={headers}
        image={Icons.settings}
        returnArray
        listNames={listNames}
        defaultSelected={defaultHeaders}
        handleSelection={select => {
          setHeadersContent(select);
        }}
      />
    </div>
  );
};

const TableCell = ({ getValue, row, column, table, index, width }) => {
  const token = useSelector(state => state.auth.token);
  const userData = useSelector(state => state.auth.userdata);

  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    if (table.options.meta?.updateData) {
      table.options.meta.updateData(row.index, column.id, parseFloat(value));
      table.options.meta.setErrorReferenceData(parseFloat(value) === 0);
    }
  };

  return (
    <div
      className={`${column.id === 'type' ? 'info-icon-live' : ''}`}
      style={{ width, overflow: column.id === 'exchange' || column.id === 'isin' ? 'initial' : '' }}
    >
      {column.id === 'isin' ? (
        <div style={{ display: 'flex' }}>
          {value?.includes('CASH_') ? (
            <BitaSelect
              data={CURRENCIES_ARRAY.map(item => {
                return { label: `CASH_${item}`, value: `CASH_${item}` };
              })}
              clearable={false}
              value={{ label: value, value }}
              handleSelection={val => {
                if (table.options.meta?.updateData) {
                  table.options.meta.updateData(row.index, column.id, val?.value);
                }
              }}
            />
          ) : (
            value ?? '-'
          )}
        </div>
      ) : column.id === 'type' ? (
        value === undefined || value === 'with_coverage' ? (
          <FaCheckSquare className="icon-info" />
        ) : (
          <BiSolidError className="icon-error" />
        )
      ) : column.id === 'shares' && row.original.isin ? (
        <BitaInput
          style={{ background: 'transparent' }}
          value={value}
          type="number"
          inputStyle={value === 0 ? 'needValidation' : ''}
          handleChange={val => setValue(val.replace(/,/g, ''))}
          onBlur={onBlur}
        />
      ) : column.id === 'exchange' &&
        value &&
        !value?.includes('CASH') &&
        !hasValidUBSOnly(userData.id_company) ? (
        <div style={{ display: 'flex' }}>
          {' '}
          <BitaSelect
            data={table.options.meta?.listMic.map(item => {
              return { label: item, value: item };
            })}
            clearable={false}
            value={{ label: value, value }}
            handleSelection={val => {
              if (table.options.meta?.updateData) {
                table.options.meta.updateData(row.index, column.id, val?.value);
              }
              reCalculateCoverage(token, {
                constituents: [
                  {
                    isin: row.original.isin,
                    exchange: val?.value,
                  },
                ],
                calculation_setup: table.options.meta?.calculation_setup,
              })
                .then(rsp => {
                  if (!rsp.data.data.full_coverage) {
                    toast.error(
                      'The MIC you recently edited does not have coverage for the specified ISIN',
                    );
                  }
                  table.options.meta.updateData(
                    row.index,
                    'type',
                    rsp.data.data.full_coverage ? 'with_coverage' : 'request_coverage',
                  );
                })
                .catch(error => {
                  console.info(error);
                });
            }}
            // selectClassname={hasValidUBSOnly(userData.id_company) ? 'disabledDropdown' : ''}
          />
        </div>
      ) : column.id === 'sedol' && true ? (
        <div style={{ display: 'flex' }}>
          {index === 0 && (
            <BitaCheckbox
              checked={table.options.meta.constituentsDelete
                ?.map(item => item.isin ?? item.sedol)
                ?.includes(row.original.isin ?? row.original?.sedol)}
              handleChange={checked => {
                table.options.meta.updateCheckbox(row.original, checked);
              }}
            />
          )}
          {value ?? '-'}
        </div>
      ) : column.id === 'All select' ? (
        <BitaCheckbox
          checked={table.options.meta.constituentsDelete
            ?.map(item => item.isin ?? item.sedol)
            ?.includes(row.original?.isin ?? row.original?.sedol)}
          handleChange={checked => {
            table.options.meta.updateCheckbox(row.original, checked);
          }}
        />
      ) : column.id === 'weights' ? (
        value?.toFixed(6)
      ) : (
        value ?? '-'
      )}
      {column.id === 'type' && !(value === undefined || value === 'with_coverage') && (
        <div className="tooltip error-tooltip">
          <div>
            <BiX className="tooltip-icon icon-error" />
          </div>
          <span>
            {value === 'not_found'
              ? `This instrument is not covered by BITA's Reference Database. 
              You can request coverage using the button below, 
              " Request Coverage." We will receive the request, process it, 
              and communicate with you via e-mail as soon as possible.`
              : `This instrument is not under the BITA Calculation platform pricing
              coverage. You can request the coverage using the button below " Request Coverage ". We will receive the request, process it, and
              communicate with you via e-mail as soon as possible.`}
          </span>
        </div>
      )}
    </div>
  );
};

// Helper de columnas
const columnHelper = createColumnHelper();

const Table = ({
  basket,
  rows,
  hiddenColumn = [],
  setMetadata,
  setConstituentsDelete,
  constituentsDelete,
  listMic,
  calculation_setup,
  setErrorReferenceData,
  allDelete,
}) => {
  const userdata = useSelector(state => state.auth.userdata);
  const [columnVisibility, setColumnVisibility] = useState(VISIBILITY_INITIAL);
  const [data, setData] = useState(() => [...(rows ?? [])]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const itemsColumns = Object.keys(
    {
      ...data.filter(item => !item?.isin?.includes('CASH') || data.length === 1)?.[0],
      type: data?.[0] ?? '',
    } ?? {},
  ).filter(item => !hiddenColumn.includes(item));

  const headerColumn = {
    exchange: 'MIC',
    isin: 'ISIN',
    awf: 'AWF',
    iwf: 'IWF',
    shares: 'Shares',
    ticker: 'Ticker',
    sedol: 'SEDOL',
    type: 'Status',
    weights: 'Last Close Weights',
    company_name: 'Company Name',
  };

  const headerFilter = {
    isin: true,
    sedol: true,
    ticker: true,
    company_name: true,
  };

  const columns = [
    'All select',
    ...(itemsColumns?.includes('sedol') && hasValidUBSOnly(userdata.id_company)
      ? ORDERED_COLUMNS_WITH_VALID_UBS.filter(col => itemsColumns.includes(col)).concat(
          itemsColumns.filter(item => !ORDERED_COLUMNS_WITH_VALID_UBS.includes(item)),
        )
      : ORDERED_COLUMNS_WITHOUT_VALID_UBS.filter(col => itemsColumns.includes(col)).concat(
          itemsColumns.filter(item => !ORDERED_COLUMNS_WITHOUT_VALID_UBS.includes(item)),
        )),
  ].map((item, key) => {
    return columnHelper.accessor(item, {
      header: headerColumn?.[item] ? headerColumn?.[item] : item,
      cell: TableCell,
      enableHiding: false,
      enableGlobalFilter: headerFilter[item] ?? false,
      size: HEADERSIZEINITIAL[item] ?? 200,
      minSize: item === 'exchange' || item === 'isin' ? 120 : item === 'All select' ? 30 : 40,
    });
  });
  const fuzzyFilter = (row, columnIds, filterValue) => {
    const itemValue = row.getValue(columnIds) ? row.getValue(columnIds)?.toString() : '';
    return itemValue.toLowerCase().includes(filterValue.toLowerCase());
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      globalFilter,
      sorting,
      pagination,
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    // onPaginationChange: setPagination,
    globalFilterFn: fuzzyFilter,
    meta: {
      constituentsDelete,
      listMic,
      calculation_setup,
      setErrorReferenceData,
      updateData: (rowIndex, columnId, value) => {
        setData(old =>
          old.map((row, index) => {
            if (
              index === rowIndex &&
              columnId === 'isin' &&
              row.isin?.includes('CASH_') &&
              row?.sedol
            ) {
              return {
                ...old[rowIndex],
                sedol: value,
                [columnId]: value,
              };
            }
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          }),
        );
        setMetadata(prev => {
          return {
            ...prev,
            meta_reference_data: prev?.meta_reference_data.map((row, index) => {
              if (
                index === rowIndex &&
                columnId === 'isin' &&
                columnId === 'isin' &&
                row.isin?.includes('CASH_') &&
                row.sedol
              ) {
                return {
                  ...prev?.meta_reference_data?.[rowIndex],
                  sedol: value,
                  [columnId]: value,
                };
              }
              if (index === rowIndex) {
                return {
                  ...prev?.meta_reference_data?.[rowIndex],
                  [columnId]: value,
                };
              }
              return row;
            }),
          };
        });
      },
      updateCheckbox: (value, checked) => {
        setConstituentsDelete(value);
      },
    },
  });

  useEffect(() => {
    setData(rows);
  }, [rows]);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [globalFilter]);

  useEffect(() => {
    setGlobalFilter('');
    setData(rows);
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [basket]);

  const handlePageChange = newPageIndex => {
    setPagination({ ...pagination, pageIndex: newPageIndex });
  };

  return (
    <div className="basket-table">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <DropdownSelect
          headers={itemsColumns}
          listNames={headerColumn}
          defaultHeaders={Object.keys(columnVisibility).filter(key => columnVisibility[key])}
          setHeadersContent={newHeaders => {
            const result = Object.fromEntries(
              itemsColumns.map(key => [key, newHeaders.includes(key)]),
            );
            setColumnVisibility(result);
          }}
        />
        <div>
          <input
            className="searchInput"
            type="text"
            value={globalFilter}
            onChange={e => setGlobalFilter(e.target.value)}
            placeholder="Search by..."
          />
        </div>
      </div>
      <div className="contentScrollTable" data-simplebar style={{ height: '500px', width: '100%' }}>
        <table
          style={{
            width: table.getCenterTotalSize(),
          }}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ position: 'relative', width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : header.id === 'All select' ? (
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                          <BitaCheckbox
                            checked={constituentsDelete.length === rows.length}
                            handleChange={checked => {
                              allDelete(checked ? rows : []);
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                          style={{ display: 'flex', alignItems: 'center' }}
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                              : undefined
                          }
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <FaSortUp />,
                            desc: <FaSortDown />,
                          }[header.column.getIsSorted()] ?? <FaSort />}
                          {header.column.getCanResize() && (
                            <div
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              className={`resizer ${
                                header.column.getIsResizing() ? 'isResizing' : ''
                              }`}
                            />
                          )}
                          {header.id === 'weights' && (
                            <span>
                              Editing the shares won&apos;t change this column values. Only
                              end-of-day calculation is considered for showing values in this
                              column.
                            </span>
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                style={{
                  background:
                    !row?.original?.type || row?.original?.type === 'with_coverage'
                      ? ''
                      : 'rgba(243, 87, 87, 0.53)',
                }}
              >
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={`${cell.id}-${cell.row.original.isin}`}
                    style={{ width: cell.column.getSize() }}
                  >
                    {flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      index,
                      width: cell.column.getSize(),
                    })}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <BitaButton
          style={{ width: '35px', height: '25px', marginRight: '5px' }}
          primaryWhite
          disabled={!table.getCanPreviousPage()}
          onClick={() => {
            table.firstPage();
            handlePageChange(0);
          }}
        >
          {'<<'}
        </BitaButton>
        <BitaButton
          style={{ width: '35px', height: '25px', marginRight: '5px' }}
          primaryWhite
          disabled={!table.getCanPreviousPage()}
          onClick={() => {
            table.previousPage();
            handlePageChange(table.getState().pagination.pageIndex - 1);
          }}
        >
          {'<'}
        </BitaButton>
        <BitaButton
          style={{ width: '35px', height: '25px', marginRight: '5px' }}
          primaryWhite
          onClick={() => {
            table.nextPage();
            handlePageChange(table.getState().pagination.pageIndex + 1);
          }}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </BitaButton>
        <BitaButton
          style={{ width: '35px', height: '25px', marginRight: '5px' }}
          primaryWhite
          onClick={() => {
            table.lastPage();
            handlePageChange(table.getPageCount() - 1);
          }}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </BitaButton>
        <span style={{ marginLeft: '10px' }}>
          {table.getState().pagination.pageIndex + 1}/{table.getPageCount().toLocaleString()}
        </span>
      </div>
    </div>
  );
};

export default Table;
